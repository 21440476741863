<template>
    <!-- 仓库主页 -->
    <div class="warehouse">
        <div class="wh-head mb12" v-if="whState.statics">
            <div class="wh-head-card" @click="toDetail('/adhibition/storage/warning')">
                <div class="wh-head-icon icon1">
                    <div class="whhi-cycle"><i class="ri-alert-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>{{  whState.statics.waringCount }}</p>
                    <span class="title">预警信息</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon2">
                    <div class="whhi-cycle"><i class="ri-download-2-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>{{ whState.statics.inboundCount}}</p>
                    <span class="title">今日入库笔数</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon3">
                    <div class="whhi-cycle"><i class="ri-logout-circle-r-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>{{ whState.statics.outboundCount }}</p>
                    <span class="title">今日出库笔数</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon4">
                    <div class="whhi-cycle"><i class="ri-copper-coin-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>{{ whState.statics.sale_price }}</p>
                    <span class="title">今日销售金额</span>
                </div>
            </div>
            <div class="wh-head-card">
                <div class="wh-head-icon icon5">
                    <div class="whhi-cycle"><i class="ri-money-cny-circle-line"></i></div>
                </div>
                <div class="wh-head-right">
                    <p>{{ whState.statics.purchase_price }}</p>
                    <span class="title">今日采购金额</span>
                </div>
            </div>
        </div>

        <div class="el-content mb12">
            <a-space>
                <a-select
                    v-model:value="whState.search.type"
                    placeholder="请选择物品类型"
                    style="width: 200px"
                >
                    <a-select-option value="all">全部</a-select-option>
                    <a-select-option value="1">产品</a-select-option>
                    <a-select-option value="2">农资</a-select-option>
                </a-select>
                <a-input-search
                    v-model:value="whState.search.key"
                    placeholder="搜索物品编号、名称"
                    enter-button
                    style="width: 400px"
                    @search="getWarehouseList(1,whState.limit)"
                />
            </a-space>
        </div>
        <div class="el-content">
            <div class="kd-pacel">列表</div>
            <a-table :pagination="false" rowKey="id" :data-source="whState.list" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'仓库',dataIndex:'depotName'},
                        {title:'编号',dataIndex:'bar_code'},
                        {title:'物品名称',dataIndex:'name'},
                        {title:'规格型号',dataIndex:'model'},
                        {title:'单位',dataIndex:'unit'},
                        {title:'备注',dataIndex:'remark'},
                    ]" >
            </a-table>
            <kd-pager :page-data="whState" :event="getWarehouseList"></kd-pager>
        </div>
<!--        <a-row :gutter="[16,16]">-->
<!--            <a-col :span="18">-->

<!--            </a-col>-->
<!--            <a-col :span="6">-->
<!--                <div class="wh-oprate">-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/inbound_edit')">-->
<!--                        <i class="ri-download-2-line"></i>-->
<!--                        <span>添加入库</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/outbound_edit')">-->
<!--                        <i class="ri-logout-circle-r-line"></i>-->
<!--                        <span>添加出库</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/customer')">-->
<!--                        <i class="ri-account-box-fill"></i>-->
<!--                        <span>客户管理</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/supplier')">-->
<!--                        <i class="ri-user-star-fill"></i>-->
<!--                        <span>供应商管理</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/entrepot')">-->
<!--                        <i class="ri-home-8-fill"></i>-->
<!--                        <span>仓库管理</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/product')">-->
<!--                        <i class="ri-shopping-bag-3-fill"></i>-->
<!--                        <span>产品管理</span>-->
<!--                    </div>-->
<!--                    <div class="wh-oprate-item" @click="toDetail('/adhibition/storage/farm_data')">-->
<!--                        <i class="ri-profile-fill"></i>-->
<!--                        <span>农资管理</span>-->
<!--                    </div>-->

<!--                </div>-->
<!--            </a-col>-->
<!--        </a-row>-->
    </div>
</template>
<script >
import router from '@/router'
import { useWarehouse } from '@/models/addons/storage'
export default {
    setup() {
        let { whState,getWarehouseList } = useWarehouse()
        getWarehouseList(1,whState.limit )

        function toDetail(path){
            router.push(path)
        }

        return{
            toDetail,
            whState,getWarehouseList
        }
    },
}
</script>
<style scoped lang="scss">
.wh-head{
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-card{
        background: #fff;
        padding: 12px;
        width: 19%;
        height: 100px;
        display: flex;
        cursor: pointer;
    }

    &-icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 10px;

        .whhi-cycle{
            width: 40px;
            height: 40px;
            color: #fff;
            border-radius: 50%;
            line-height: 40px;
            text-align: center;

            i{
                font-size: 18px;
            }
        }
    }

    .icon1{
        background: rgba($color: #FF0066, $alpha: .2);
        .whhi-cycle{
            background: #FF0066;
        }
    }
    .icon2{
        background: rgba($color: #33CC99, $alpha: .2);
        .whhi-cycle{
            background: #33CC99;
        }
    }
    .icon3{
        background: rgba($color: #33CCFF, $alpha: .2);
        .whhi-cycle{
            background: #33CCFF;
        }
    }
    .icon4{
        background: rgba($color: #6699FF, $alpha: .2);
        .whhi-cycle{
            background: #6699FF;
        }
    }
    .icon5{
        background: rgba($color: #FF9900, $alpha: .2);
        .whhi-cycle{
            background: #FF9900;
        }
    }

    &-right{
        margin-left: 12px;
        p{
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 2px;
            margin-top: 8px;
        }
        .title{
            color: #b4c1ce;
            font-size: 12px;
        }
    }

}

.wh-oprate{
    width: 100%;

    &-item{
        width: 100%;
        height: 60px;
        background: #fff;
        margin-bottom: 6px;
        padding: 18px 24px;
        cursor: pointer;

        span{
            font-size: 16px;
            font-weight: bold;
            margin-left: 12px;
        }
    }
}
</style>
